import { Box, Grid, Typography, useTheme } from "@mui/material";

export function AboutUsHero() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: theme.spacing(10, 6),
        display: "grid",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={3}>
        <Grid container item xs={12}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography
              variant="h4"
              color={theme.palette.primary.dark}
              textAlign="center"
            >
              Made for Group Owners, Developers, & Staff
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography textAlign="center">
              We provide unparalleled experience for both group owners, group
              staff members, and developers alike. In 2018, Akomi's ownership
              team suffered the loss of over 30,000 group members in the matter
              of minutes. How? Simply because a staff member had permission to
              exile group members and used the Roblox API to do this.
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography textAlign="center">
              From then on, we resolved to make a ranking service that fulfilled
              these main goals: (1) protect the group from mass rankings or
              exiles, (2) allow staff members to still rank members, and (3)
              provide an easy-to-use developer experience. We created Akomi - a
              solution that would solve all of these problems by ensuring that
              staff members are granted the least amount of permissions in
              group, while still allowing them to have these privileges in the
              Discord server.
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography textAlign="center">
              Today, we are still committed to making the best experience for
              both group managers and developers by providing a easy-to-use
              portal that is both intuitive and affordable. For developers, we
              allow customizable experiences by allowing developers to integrate
              our API into any game's code.
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    </Box>
  );
}
