import { Alert, Snackbar } from "@mui/material";
import { RootStateType, useTypedDispatch } from "@redux/store";
import { closeAlert } from "@redux/system";
import { useSelector } from "react-redux";

export function SystemAlert() {
  const dispatch = useTypedDispatch();

  const { open, variant, message } = useSelector(
    (state: RootStateType) => state.system.alert
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => {
        dispatch(closeAlert());
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Alert
        variant="filled"
        severity={variant}
        onClose={() => {
          dispatch(closeAlert());
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
