import { Box, Button, Grid, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { openDialog } from "@redux/system";
import { useTypedDispatch } from "@redux/store";
import { CreateOrganizationBody } from "./dialogBody/CreateOrganizationBody";

export function Searchbar() {
  const dispatch = useTypedDispatch();
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleOpenCreateOrganization = () => {
    dispatch(
      openDialog({
        open: true,
        title: "Create Organization",
        description:
          "Please fill out all of the details below to create your organization.",
        dialogContent: <CreateOrganizationBody />,
      })
    );
  };

  return (
    <Box
      sx={{
        padding: theme.spacing(10, 6),
      }}
    >
      <Grid container alignItems={"center"}>
        <Grid item xs={10}>
          <TextField
            placeholder="Search"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            inputProps={{
              style: {
                padding: theme.spacing(2.5, 3),
              },
            }}
            sx={{
              width: "97.5%",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenCreateOrganization}
            sx={{
              width: "100%",
            }}
          >
            Create Organization
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
