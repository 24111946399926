import { Box } from "@mui/material";
import { PricingTab } from "./PricingTab";

export function TabHero() {
  return (
    <Box>
      <PricingTab />
    </Box>
  );
}
