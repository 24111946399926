import { RootStateType, useTypedDispatch } from "@redux/store";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { closeDialog } from "@redux/system";

export function SystemDialog() {
  const dispatch = useTypedDispatch();

  const {
    open,
    title,
    description,
    leftButtonTitle,
    rightButtonTitle,
    leftButtonColor,
    rightButtonColor,
    leftButtonAction,
    rightButtonAction,
    dialogContent,
  } = useSelector((state: RootStateType) => state.system.dialog);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={() => {
        dispatch(closeDialog());
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {dialogContent}
      </DialogContent>
      {(leftButtonTitle || rightButtonTitle) && (
        <DialogActions>
          {leftButtonTitle && (
            <Button
              onClick={leftButtonAction}
              color={leftButtonColor ?? "primary"}
            >
              {leftButtonTitle}
            </Button>
          )}
          {rightButtonTitle && (
            <Button
              onClick={rightButtonAction}
              color={rightButtonColor ?? "primary"}
            >
              {rightButtonTitle}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
