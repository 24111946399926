import { AboutUsHero } from "@components/landing/AboutUsHero";
import { FeatureHero } from "@components/landing/FeatureHero";
import { MainHero } from "@components/landing/MainHero";
import { Navbar } from "@components/shared/Navbar";
import { PricingHero } from "@components/landing/PricingHero";
import { TryHero } from "@components/shared/TryHero";
import { Footer } from "@components/shared/Footer";
import { SystemDialog } from "@components/shared/SystemDialog";
import { SystemAlert } from "@components/shared/SystemAlert";

export function Home() {
  return (
    <>
      <Navbar current="home" />
      <MainHero />
      <FeatureHero />
      <AboutUsHero />
      <PricingHero />
      <TryHero />
      <Footer />
      <SystemDialog />
      <SystemAlert />
    </>
  );
}
