import { MainHero } from "@components/pricingPage/MainHero";
import { Navbar } from "@components/shared/Navbar";
import { FullFeatureList } from "@components/pricingPage/FullFeatureList";
import { TabHero } from "@components/pricingPage/TabHero";
import { TryHero } from "@components/shared/TryHero";
import { Footer } from "@components/shared/Footer";
import { SystemDialog } from "@components/shared/SystemDialog";
import { SystemAlert } from "@components/shared/SystemAlert";

export function Pricing() {
  return (
    <>
      <Navbar current="pricing" />
      <MainHero />
      <TabHero />
      <FullFeatureList />
      <TryHero />
      <Footer />
      <SystemDialog />
      <SystemAlert />
    </>
  );
}
