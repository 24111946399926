import { Box, Grid, useTheme } from "@mui/material";
import { OrganizationCard } from "./OrganizationCard";
import { useEffect, useState } from "react";
import { backendPathResolver } from "@actions/backendPathResolver";

interface OrganizationData {
  organizationName: string;
  organizationImage: string;
}

export function OrganizationList() {
  const theme = useTheme();

  const [organizations, setOrganizations] = useState<OrganizationData[]>([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const data = await fetch(backendPathResolver("/website/organization"), {
        credentials: "include",
      }).then((res) => res.json());

      const orgData: OrganizationData[] = data.data.map((org: any) => {
        return {
          organizationName: org.name,
          organizationImage: org.groupImage,
        };
      });

      setOrganizations(orgData);
    };
    fetchOrganizations();
  }, []);

  return (
    <Box
      sx={{
        padding: theme.spacing(0, 6, 10),
      }}
    >
      <Grid container spacing={10} justifyContent={"center"}>
        {organizations.map((org) => (
          <Grid item xs={3}>
            <OrganizationCard
              organizationName={org.organizationName}
              organizationImage={org.organizationImage}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
