import { PricingTab } from "@components/pricingPage/PricingTab";
import { Box, Typography, useTheme } from "@mui/material";

export function PricingHero() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: theme.spacing(10, 6),
      }}
    >
      <Typography
        variant="h2"
        color={theme.palette.primary.main}
        textAlign="center"
      >
        <u>Pricing</u>
      </Typography>
      <PricingTab />
      <Typography variant="body2" textAlign="center">
        * All requests to our API services are ratelimited. Visit the Pricing
        page for more details.
      </Typography>
      <Typography variant="body2" textAlign="center">
        ** Robux equivalent pricing is also available, please visit the Pricing
        page for more information.
      </Typography>
    </Box>
  );
}
