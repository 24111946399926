import { Box, Typography, useTheme } from "@mui/material";

export function MainHero() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: theme.spacing(10, 6),
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Typography
        variant="h2"
        color={theme.palette.primary.contrastText}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Privacy Policy
      </Typography>
    </Box>
  );
}
