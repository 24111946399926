import { featureHero1, featureHero2, featureHero3 } from "@assets/index";
import { Box, Grid, Typography, useTheme } from "@mui/material";

export function FeatureHero() {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h2"
        color={theme.palette.primary.main}
        sx={{
          display: "grid",
          justifyContent: "center",
          mt: theme.spacing(10),
        }}
      >
        <u>Our Features</u>
      </Typography>
      <Grid
        container
        sx={{
          margin: theme.spacing(10, 0),
        }}
        direction="column"
      >
        <Grid
          container
          item
          xs={12}
          spacing={7}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6} justifyContent="flex-end" display="flex">
            <img
              src={featureHero1}
              alt="Feature Hero"
              style={{
                maxWidth: "520px",
                maxHeight: "346px",
                justifyContent: "flex-end",
                display: "flex",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" color={theme.palette.primary.dark}>
              Application Center
            </Typography>
            <Typography
              sx={{
                width: "90%",
              }}
            >
              We provide first class application centers that are second to
              none. Our application centers have the aesthetics of a modern
              application that you would find online and the capabilities that
              you expect from an application service. With our diverse range of
              question types, we can confidently say that you will never need
              another application center again.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6} justifyContent="flex-end">
            <Typography
              variant="h4"
              color={theme.palette.primary.dark}
              justifyContent="flex-end"
              display="flex"
            >
              Ranking Center
            </Typography>
            <Typography
              sx={{
                ml: "10%",
                width: "90%",
              }}
              textAlign="right"
              display="flex"
            >
              Ever wanted to make a group rank tied to a certain asset? With
              this ranking center, you can easily customize what roles you want
              to link and how you want to link them. Customize your user's
              experience by providing them with an intuitive way to get roles
              for their assets.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <img
              src={featureHero2}
              alt="Feature Hero"
              style={{
                maxWidth: "448px",
                maxHeight: "448px",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6} justifyContent="flex-end" display="flex">
            <img
              src={featureHero3}
              alt="Feature Hero"
              style={{
                maxWidth: "380px",
                maxHeight: "380px",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" color={theme.palette.primary.dark}>
              Discord Bot Integration
            </Typography>
            <Typography
              sx={{
                width: "90%",
              }}
            >
              Along with our application and ranking centers, we also offer a
              Discord bot that you can use to connect to your Roblox group. Use
              group management commands in the comfort of your Discord server
              and say goodbye to managing member ranks from Roblox!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
