import { createSlice } from "@reduxjs/toolkit";

export interface SliceType {
  reloadOrganization: boolean;
  reloadCenters: boolean;
}

const slice = createSlice({
  name: "reloads",
  initialState: {
    reloadOrganization: false,
    reloadCenters: false,
  } as SliceType,
  reducers: {
    setReloadOrganizations: (state) => {
      state.reloadOrganization = !state.reloadOrganization;
    },
    setReloadCenters: (state) => {
      state.reloadCenters = !state.reloadCenters;
    },
  },
});

export const { setReloadOrganizations, setReloadCenters } = slice.actions;
export default slice.reducer;
