import { Box, Typography, useTheme } from "@mui/material";

export function Footer() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        mt: "auto",
        padding: theme.spacing(10, 6),
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.primary.contrastText,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <a
          href="/"
          style={{
            color: theme.palette.primary.contrastText,
            textDecoration: "none",
          }}
        >
          Home
        </a>
        &nbsp;-&nbsp;
        <a
          href="/pricing"
          style={{
            color: theme.palette.primary.contrastText,
            textDecoration: "none",
          }}
        >
          Pricing
        </a>
        &nbsp;-&nbsp;
        <a
          href="https://discord.com/4nYtVaSNXx"
          target="_blank"
          rel="noreferrer"
          style={{
            color: theme.palette.primary.contrastText,
            textDecoration: "none",
          }}
        >
          Help
        </a>
        &nbsp;-&nbsp;Terms & Conditions&nbsp;-&nbsp;Privacy Policy
      </Typography>

      <br />

      <Typography
        variant="body2"
        sx={{
          color: theme.palette.primary.contrastText,
          justifyContent: "center",
          display: "flex",
        }}
      >
        ©️ 2023 Akomi
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.primary.contrastText,
          justifyContent: "center",
          display: "flex",
        }}
      >
        Created by NicholasY4815
      </Typography>
    </Box>
  );
}
