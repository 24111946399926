import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

export interface SliceType {
  socket: any;
  userID: string | null;
  userName: string | null;
}

const slice = createSlice({
  name: "user",
  initialState: {
    socket: null,
    userID: null,
    userName: null,
  } as SliceType,
  reducers: {
    setSocket: (state, action: PayloadAction<Socket | null>) => {
      state.socket = action.payload;
    },
    setUserID: (state, action: PayloadAction<string | null>) => {
      state.userID = action.payload;
    },
    setUserName: (state, action: PayloadAction<string | null>) => {
      state.userName = action.payload;
    },
  },
});

export const { setSocket, setUserID, setUserName } = slice.actions;
export default slice.reducer;
