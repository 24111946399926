import { MainHero } from "@components/organizations/MainHero";
import { OrganizationList } from "@components/organizations/OrganizationList";
import { Searchbar } from "@components/organizations/Searchbar";
import { Footer } from "@components/shared/Footer";
import { Navbar } from "@components/shared/Navbar";
import { SystemAlert } from "@components/shared/SystemAlert";
import { SystemDialog } from "@components/shared/SystemDialog";
import { Box } from "@mui/material";

export function Organizations() {
  return (
    <>
      <Navbar />
      <MainHero />
      <Searchbar />
      <Box
        sx={{
          minHeight: "50vh",
        }}
      >
        <OrganizationList />
      </Box>
      <Footer />
      <SystemDialog />
      <SystemAlert />
    </>
  );
}
