import { mainHero1 } from "@assets/index";
import { Box, Grid, Typography, useTheme } from "@mui/material";

export function MainHero() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mv: {
          lg: theme.spacing(30),
          padding: theme.spacing(30, 6),
        },
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Grid container alignItems={"center"}>
        <Grid item lg={7}>
          <Typography
            variant="h1"
            color={theme.palette.primary.contrastText}
            sx={{
              textShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;",
            }}
          >
            Applications
          </Typography>
          <Typography variant="h1" color={theme.palette.primary.main}>
            reimagined
          </Typography>
          <Typography variant="h4" color={theme.palette.primary.contrastText}>
            Aesthetic, automatic, and affordable
          </Typography>
        </Grid>
        <Grid item lg={5}>
          <img
            src={mainHero1}
            alt="Main Hero"
            style={{
              maxWidth: "464px",
              maxHeight: "356px",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
