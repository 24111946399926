import { createTheme, ThemeOptions } from "@mui/material/styles";
import { CSSProperties } from "react";

// This page sets global configuration variables for Material UI

declare module "@mui/material/styles" {
  /* PaletteOptions extends the MUI interface and is very-much used
  (albeit implicitly) by this object */
  interface PaletteOptions {
    logo: {
      color: CSSProperties["color"];
    };
  }
}
/* Use this object to customize specific MUI atoms. It is shared by lightTheme
and darkTheme. */
const components: ThemeOptions["components"] = {};

const typography: ThemeOptions["typography"] = {
  fontFamily: "Poppins",
  subtitle1: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  subtitle2: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.1px",
  },
  allVariants: {
    color: "var(--text-primary)",
    whiteSpace: "initial",
    fontWeight: "",
  },
  h6: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "160%",
    letterSpacing: "0.15px",
  },
  body1: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
  },
  body2: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    textTransform: "none",
  },
  caption: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "166%",
    letterSpacing: "0.4px",
  },
  overline: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "31.92px",
    letterSpacing: "1px",
    letter: "0.17px",
  },
};

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    primary: {
      main: "#2196F3",
      dark: "#1A237E",
      light: "#BBDEFB",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#0b1b2b",
      dark: "#040c13",
      light: "#545f6b",
      contrastText: "#ffffff",
    },
    error: {
      main: "#ff2e00",
      dark: "#b22000",
      light: "#ff5733",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#ff9614",
      dark: "#ff820e",
      light: "#ffbb33",
      contrastText: "#ffffff",
    },
    info: {
      main: "#1f5fff",
      dark: "#003cd2",
      light: "#5284ff",
      contrastText: "#ffffff",
    },
    success: {
      main: "#4d9c32",
      dark: "#34801f",
      light: "#82ba70",
      contrastText: "#ffffff",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      focus: "rgba(0, 0, 0, 0.12)",
    },
    logo: {
      color: "#0b1b2b",
    },
  },
  components,
  typography,
  spacing: 4,
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    text: {
      primary: "#ffffff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    primary: {
      main: "#2196F3",
      dark: "#1A237E",
      light: "#BBDEFB",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#cbdaff",
      dark: "#a9c2ff",
      light: "#eaf0ff",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    error: {
      main: "#ff6d4d",
      dark: "#ff4d26",
      light: "#ff9780",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#ffc552",
      dark: "#ffb52e",
      light: "#ffdd99",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#628fff",
      dark: "#4177ff",
      light: "#8fafff",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      main: "#82ba70",
      dark: "#68ab51",
      light: "#a6ce99",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    action: {
      active: "rgba(255, 255, 255, 0.56)",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      focus: "rgba(255, 255, 255, 0.12)",
    },
    logo: {
      color: "#ffffff",
    },
  },
  components,
  typography,
  spacing: 4,
});
