import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const features: {
  [key: string]: { text: string; flags?: boolean[]; data?: string[] };
} = {
  createAppCenter: {
    text: "Create & Design Application Centers",
    flags: [true, true, true],
  },
  createRankCenter: {
    text: "Create & Design Ranking Centers",
    flags: [true, true, true],
  },
  discordBotIntegration: {
    text: "Akomi Discord Bot Integration",
    flags: [true, true, true],
  },
  createApiKeys: {
    text: "Create API Keys",
    flags: [true, true, true],
  },
  webhookProxy: {
    text: "Webhook Proxy",
    flags: [true, true, true],
  },
  granularPermissions: {
    text: "Granular Permissions",
    flags: [true, true, true],
  },
  automaticRanking: {
    text: "Automatic Ranking",
    flags: [true, true, true],
  },
  prioritySupport: {
    text: "Priority Support",
    flags: [false, true, true],
  },
  betaFeatures: {
    text: "Access to Beta Features",
    flags: [false, true, true],
  },
  discordRole: {
    text: "Discord Role in Support Server",
    flags: [false, true, true],
  },
  dedicatedSupport: {
    text: "Dedicated Support",
    flags: [false, false, true],
  },
  application: {
    text: "Application Quota",
    data: ["300 Applications/Month", "1,500 Applications/Month", "Unlimited"],
  },
  ranking: {
    text: "Ranking Quota",
    data: ["750 Rankings/Month", "3,000 Rankings/Month", "Unlimited"],
  },
  webhook: {
    text: "Webhook Quota",
    data: [
      "1,500 Webhook Calls/Month",
      "5,000 Webhook Calls/Month",
      "Unlimited",
    ],
  },
};

export function FullFeatureList() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: theme.spacing(10, 6),
      }}
    >
      <Typography
        variant="h3"
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Full Feature List
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Features</TableCell>
            <TableCell align="center">Free</TableCell>
            <TableCell align="center">Premium</TableCell>
            <TableCell align="center">Enterprise</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(features).map((key: string) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {features[key].text}
              </TableCell>
              <TableCell align="center">
                {features[key].flags ? (
                  features[key].flags?.[0] ? (
                    <CheckCircleIcon
                      sx={{
                        color: theme.palette.success.main,
                      }}
                    />
                  ) : null
                ) : (
                  features[key].data?.[0]
                )}
              </TableCell>
              <TableCell align="center">
                {features[key].flags ? (
                  features[key].flags?.[1] ? (
                    <CheckCircleIcon
                      sx={{
                        color: theme.palette.success.main,
                      }}
                    />
                  ) : null
                ) : (
                  features[key].data?.[1]
                )}
              </TableCell>
              <TableCell align="center">
                {features[key].flags ? (
                  features[key].flags?.[2] ? (
                    <CheckCircleIcon
                      sx={{
                        color: theme.palette.success.main,
                      }}
                    />
                  ) : null
                ) : (
                  features[key].data?.[2]
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
