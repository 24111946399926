import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReactNode } from "react";

interface SliceType {
  dialog: DialogType;
  alert: AlertType;
}

interface DialogType {
  open: boolean;
  title: string;
  description: string;
  leftButtonTitle?: string;
  rightButtonTitle?: string;
  leftButtonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  rightButtonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  leftButtonAction?: () => void;
  rightButtonAction?: () => void;
  dialogContent?: ReactNode;
}

interface AlertType {
  open: boolean;
  variant: "error" | "info" | "success" | "warning";
  message: string;
}

const slice = createSlice({
  name: "systems",
  initialState: {
    dialog: {
      open: false,
      title: "",
      description: "",
    },
    alert: {
      open: false,
      variant: "success",
      message: "",
    },
  } as SliceType,
  reducers: {
    openDialog: (state, { payload }: PayloadAction<DialogType>) => {
      state.dialog = payload;
    },
    closeDialog: (state) => {
      state.dialog.open = false;
    },
    openAlert: (state, { payload }: PayloadAction<AlertType>) => {
      state.alert = payload;
    },
    closeAlert: (state) => {
      state.alert.open = false;
    },
  },
});

export const { openDialog, closeDialog, openAlert, closeAlert } = slice.actions;
export default slice.reducer;
