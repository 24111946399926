import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";

export function TryHero() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: theme.spacing(10, 6),
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Grid
        container
        spacing={10}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item xs={12}>
          <Typography
            variant="h2"
            color={theme.palette.primary.contrastText}
            justifyContent={"center"}
            display={"flex"}
          >
            <b>Ready to try us out?</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            endIcon={<LoginIcon />}
            sx={{
              width: "100%",
            }}
            href={
              process.env.NODE_ENV === "production"
                ? "https://discord.com/api/oauth2/authorize?client_id=1124872965725622312&redirect_uri=https%3A%2F%2Fapi.akomi.one%2Fwebsite%2Fauth%2Fdiscord%2Foauth2&response_type=code&scope=identify"
                : "https://discord.com/api/oauth2/authorize?client_id=1124872965725622312&redirect_uri=http%3A%2F%2Flocalhost%3A3333%2Fwebsite%2Fauth%2Fdiscord%2Foauth2&response_type=code&scope=identify"
            }
            target="_blank"
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
