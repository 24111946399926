import { Footer } from "@components/shared/Footer";
import { Navbar } from "@components/shared/Navbar";
import { MainHero } from "@components/privacyPolicy/MainHero";
import { SystemDialog } from "@components/shared/SystemDialog";
import { SystemAlert } from "@components/shared/SystemAlert";

export function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <MainHero />
      <Footer />
      <SystemDialog />
      <SystemAlert />
    </>
  );
}
