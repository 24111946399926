import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

interface OrganizationCardProps {
  organizationName: string;
  organizationImage: string;
}

export function OrganizationCard({
  organizationName,
  organizationImage,
}: OrganizationCardProps) {
  return (
    <Card
      sx={{
        boxShadow:
          "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20);",
      }}
    >
      <CardMedia
        image={organizationImage}
        title="yes"
        sx={{
          height: { xs: 250 },
        }}
      />
      <CardContent
        sx={{
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {organizationName}
        </Typography>
        <br />
        <Button color="primary" variant="contained">
          Manage
        </Button>
      </CardContent>
    </Card>
  );
}
