import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export function PricingTab() {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={9}
      sx={{
        margin: theme.spacing(10, 0),
        width: "calc(100% - 36px)",
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid item xs={4}>
        <Card
          sx={{
            boxShadow:
              "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20);",
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              Free
            </Typography>
            <Typography
              variant="body2"
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              All of the essentials
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Application Centers*</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Ranking Centers*</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Akomi Discord Bot*</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>API Keys*</ListItemText>
              </ListItem>
            </List>
            <Typography
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              <b>Free forever</b>
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={4}>
        <Card
          sx={{
            boxShadow:
              "0px 4px 18px 3px rgba(0, 0, 0, 0.12), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 6px 6px -3px rgba(0, 0, 0, 0.20);",
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              <b>Premium</b>
            </Typography>
            <Typography
              variant="body2"
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              Unlock all of our features
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Everything in Free and...</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Priority Support</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Access to Beta Features</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Discord role in support server</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Expanded Quotas</ListItemText>
              </ListItem>
            </List>
            <Typography
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              <b>
                USD{" "}
                <span
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  $2.99
                </span>
                /month**
              </b>
            </Typography>
            <Typography
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              or
            </Typography>
            <Typography
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              1,500 R$/month
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={4}>
        <Card
          sx={{
            boxShadow:
              "0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20);",
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              Enterprise
            </Typography>
            <Typography
              variant="body2"
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              For the big companies
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Everything in Premium and...</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Dedicated Support</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Custom Discord Bot</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: theme.palette.success.main,
                  }}
                >
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Unlimited Quotas</ListItemText>
              </ListItem>
            </List>
            <Typography
              justifyContent="center"
              sx={{
                display: "flex",
              }}
            >
              <b>
                <a
                  href="https://discord.com/4nYtVaSNXx"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                  }}
                >
                  Contact Sales
                </a>
              </b>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
