import { backendPathResolver } from "@actions/backendPathResolver";
import { Box, Button, Grid, TextField, useTheme } from "@mui/material";
import { useTypedDispatch } from "@redux/store";
import { closeDialog, openAlert } from "@redux/system";
import { useState } from "react";

export function CreateOrganizationBody() {
  const dispatch = useTypedDispatch();
  const theme = useTheme();

  const [groupName, setGroupName] = useState<string>();
  const [groupId, setGroupId] = useState<number>();

  console.log(groupName);

  const handleCreateOrganization = () => {
    if (!groupName || groupName === "")
      return dispatch(
        openAlert({
          open: true,
          variant: "error",
          message: "Group name is required!",
        })
      );
    if (!groupId || groupId === 0)
      return dispatch(
        openAlert({
          open: true,
          variant: "error",
          message: "Group ID is required!",
        })
      );

    const postData = async () => {
      const data = await fetch(backendPathResolver("/website/organization"), {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: groupName,
          groupId,
        }),
      }).then((res) => res.json());

      if (data.success) {
        dispatch(closeDialog());
        return dispatch(
          openAlert({
            open: true,
            variant: "success",
            message: "Organization has been successfully created.",
          })
        );
      } else {
        return dispatch(
          openAlert({
            open: true,
            variant: "error",
            message: data.message,
          })
        );
      }
    };

    postData();
  };

  return (
    <>
      <Box
        sx={{
          padding: theme.spacing(3, 3),
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              label="Organization Name"
              variant="standard"
              fullWidth
              value={groupName}
              onChange={(event) => {
                setGroupName(event.target.value);
              }}
              error={groupName === ""}
              helperText={groupName === "" ? "Group name is required!" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Group ID"
              type="number"
              variant="standard"
              fullWidth
              value={groupId}
              onChange={(event) => {
                setGroupId(parseInt(event.target.value));
              }}
              error={groupId === 0}
              helperText={groupId === 0 ? "Group ID is required!" : ""}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row-reverse",
          pr: "2px",
        }}
      >
        <Button
          sx={{
            color: theme.palette.primary.main,
            boxShadow: "0",
            mt: "16px",
          }}
          variant="text"
          onClick={handleCreateOrganization}
          disabled={groupName === "" || groupId === undefined}
        >
          Create Organization
        </Button>
        <Button
          sx={{
            color: theme.palette.error.main,
            boxShadow: "0",
            mt: "16px",
          }}
          variant="text"
          onClick={() => {
            dispatch(closeDialog());
          }}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
}
