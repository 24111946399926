import { setUserID, setUserName } from "@redux/user";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { backendPathResolver } from "@actions/backendPathResolver";
import { useNavigate } from "react-router-dom";

export const verifyCookie = createAsyncThunk(
  "user/verifyCookie",
  async (_, { dispatch }) => {
    const navigate = useNavigate();
    const response = await fetch(backendPathResolver("/website/auth"), {
      credentials: "include",
    }).then((res) => res.json());

    console.log(response);

    if (response.success) {
      dispatch(setUserName(response.data.username));
      dispatch(setUserID(response.data.userID));
    } else {
      dispatch(setUserName(null));
      dispatch(setUserID(null));
      // navigate("/");
    }
  }
);
