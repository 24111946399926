import { ThemeProvider } from "@mui/material";
import { lightTheme } from "@styles/theme";
import { Home } from "@pages/Home";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Pricing } from "@pages/Pricing";
import { Organizations } from "@pages/Organizations";
import { PrivacyPolicy } from "@pages/PrivacyPolicy";
import { TermsAndConditions } from "@pages/TermsAndConditions";
import { useEffect, useMemo } from "react";
import { useTypedDispatch } from "@redux/store";
import { io } from "socket.io-client";
import integrateSocket from "@actions/integrateSocket";
import { verifyCookie } from "@redux/thunks/verifyCookie";

function App() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const socket = useMemo(() => {
    const webSocketURL =
      process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:3333";
    return io(webSocketURL, {
      withCredentials: true,
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(integrateSocket(socket, navigate)), []);

  dispatch(verifyCookie());

  return (
    <ThemeProvider theme={lightTheme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/organizations" element={<Organizations />} />

        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
