import { useTheme } from "@mui/material";
import { Button, Grid } from "@mui/material";
import { akomiLight } from "@assets/index";
import LoginIcon from "@mui/icons-material/Login";
import { useSelector } from "react-redux";
import { RootStateType } from "@redux/store";

interface NavbarProps {
  current?: string;
}

export function Navbar({ current }: NavbarProps) {
  const theme = useTheme();

  const userName = useSelector((state: RootStateType) => state.user.userName);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        backgroundColor: theme.palette.primary.dark,
        height: theme.spacing(25),
        pl: theme.spacing(6),
      }}
      alignItems="center"
      spacing={5}
    >
      <Grid item xs={4} md={3} lg={2}>
        <img
          src={akomiLight}
          alt="Logo"
          style={{
            maxWidth: theme.spacing(43),
          }}
        />
      </Grid>
      <Grid
        item
        xs={0}
        sm={5}
        md={7}
        lg={8}
        sx={{
          display: {
            xs: "none",
            sm: "flex",
          },
        }}
      >
        <Grid container justifyContent="flex-start" spacing={5}>
          <Grid item>
            <a
              href="/"
              style={{
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.primary.contrastText,
                textDecoration: "none",
              }}
            >
              <span
                style={{ fontWeight: current === "home" ? "bold" : "normal" }}
              >
                Home
              </span>
            </a>
          </Grid>
          <Grid item>
            <a
              href="/pricing"
              style={{
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.primary.contrastText,
                textDecoration: "none",
              }}
            >
              <span
                style={{
                  fontWeight: current === "pricing" ? "bold" : "normal",
                }}
              >
                Pricing
              </span>
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://discord.com/4nYtVaSNXx"
              target="_blank"
              style={{
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.primary.contrastText,
                textDecoration: "none",
              }}
              rel="noreferrer"
            >
              Help
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={0}
        sm={3}
        md={2}
        sx={{
          mt: -2.5,
          pr: 6,
          justifyContent: "flex-end",
          alignContent: "center",
          display: {
            xs: "none",
            sm: "flex",
          },
        }}
      >
        <Button
          color="primary"
          variant="contained"
          endIcon={<LoginIcon />}
          href={
            userName
              ? "/organizations"
              : process.env.NODE_ENV === "production"
              ? "https://discord.com/api/oauth2/authorize?client_id=1124872965725622312&redirect_uri=https%3A%2F%2Fapi.akomi.one%2Fwebsite%2Fauth%2Fdiscord%2Foauth2&response_type=code&scope=identify"
              : "https://discord.com/api/oauth2/authorize?client_id=1124872965725622312&redirect_uri=http%3A%2F%2Flocalhost%3A3333%2Fwebsite%2Fauth%2Fdiscord%2Foauth2&response_type=code&scope=identify"
          }
        >
          {userName ? "Portal" : "Register"}
        </Button>
      </Grid>
    </Grid>
  );
}
