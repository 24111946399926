import { DispatchType, RootStateType } from "@redux/store";
import { setSocket } from "@redux/user";
import { Dispatch } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { Socket } from "socket.io-client";

const initHandlers = (
  socket: Socket,
  navigate: NavigateFunction,
  dispatch: DispatchType,
  getState: () => RootStateType
) => {
  socket.on("connect", () => {});

  socket.io.on("reconnect", () => {});
};

const integrateSocket =
  (socket: Socket, navigate: NavigateFunction) =>
  (dispatch: Dispatch, getState: () => RootStateType) => {
    dispatch(setSocket(socket));
    initHandlers(socket, navigate, dispatch, getState);
  };

export default integrateSocket;
