import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { useDispatch } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reloads from "./reloads";
import system from "./system";
import user from "./user";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const reducer = combineReducers({
  reloads,
  system,
  user,
});

enableMapSet();
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootStateType = ReturnType<typeof reducer>;
export type DispatchType = typeof store.dispatch;
export const useTypedDispatch: () => DispatchType = useDispatch;

export default store;

export const persistor = persistStore(store);
